import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade } from "swiper/modules";

import "swiper/swiper-bundle.css";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-fade";

import { Autoplay } from "swiper/modules";

import img1 from "../../../Assets/order-1.svg";
import deliver from "../../../Assets/home_deliverd.svg";
import electrical from "../../../Assets/electricals.svg";

import {
  Card,
  CardHeader,
  CardBody,
  Typography,
} from "@material-tailwind/react";

export default function Carousel() {
  return (
    <>
      <Swiper
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        noSwiping={false}
        simulateTouch={false}
        allowTouchMove={false}
        effect={"fade"}
        modules={[Autoplay, EffectFade]}
        className="mySwiper"
      >
        {/* <SwiperSlide>
          <div className="max-w-lg mx-auto bg-white rounded-sm shadow-md overflow-hidden md:max-w-lg m-3 flex flex-col items-center text-center px-10 py-10">
            <div className="md:flex-shrink-0">
              <img className="object-cover" src={img1} alt="images" />
            </div>
            <div className="p-8">
              <div className="tracking-wide text-xl font-semibold">
                Make An Order
              </div>
              <p className="mt-2">
                Process your order easily with our print on demand app. No
                minimum volume orders nor headaches of managing inventory costs.
              </p>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="max-w-sm mx-auto bg-white rounded-sm shadow-md overflow-hidden md:max-w-sm m-3 flex flex-col items-center text-center px-10 py-10">
            <div className="md:flex-shrink-0">
              <img className="object-cover" src={electrical} alt="images" />
            </div>
            <div className="p-8">
              <div className="tracking-wide text-xl font-semibold">
                Get it produced
              </div>
              <p className="mt-2">
                The order is sent to Cuztomi's.factory where it gets printed,
                sewn, and shipped to your customer. You'll be notified when the
                order is shipped.
              </p>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="max-w-sm mx-auto bg-white rounded-sm shadow-md overflow-hidden md:max-w-sm m-3 flex flex-col items-center text-center">
            <div className="md:flex-shrink-0">
              <img className="object-cover" src={deliver} alt="images" />
            </div>
            <div className="p-8">
              <div className="tracking-wide text-xl font-semibold">
                Deliver to your customer
              </div>
              <p className="mt-2">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Quisquam, voluptatum! Lorem ipsum dolor sit amet consectetur
                adipisicing elit.
              </p>
            </div>
          </div>
        </SwiperSlide> */}
        <SwiperSlide>
          <div className="max-w-lg mx-auto bg-white rounded-lg shadow-md overflow-hidden md:max-w-lg m-3 flex flex-col items-center text-center ">
            <Card className="w-full justify-center">
              <CardHeader floated={false} className="h-80 w-3/4 mx-auto">
                <img
                  src={img1}
                  alt="girl order"
                  className="object-cover w-full h-full"
                />
              </CardHeader>
              <CardBody>
                <Typography className="mb-2 text-center text-black text-xl font-bold">
                  Make An Order
                </Typography>
                <Typography className="text-justify font-normal text-black">
                  Process your order easily with our print on demand app. No
                  minimum volume orders nor headaches of managing inventory
                  costs.
                </Typography>
              </CardBody>
            </Card>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="max-w-lg mx-auto bg-white rounded-lg shadow-md overflow-hidden md:max-w-lg m-3 flex flex-col items-center text-center">
            <Card className="w-full justify-center">
              <CardHeader floated={false} className="h-80 w-3/4 mx-auto">
                <img
                  src={electrical}
                  alt="electric wiring"
                  className="object-cover w-full h-full"
                />
              </CardHeader>
              <CardBody>
                <Typography className="mb-2 text-center text-black text-xl font-bold">
                  Get it produced
                </Typography>
                <Typography className="text-justify font-normal text-black">
                  The order is sent to Cuztomi's.factory where it gets printed,
                  sewn, and shipped to your customer. You'll be notified when
                  the order is shipped.
                </Typography>
              </CardBody>
            </Card>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="max-w-lg mx-auto bg-white rounded-lg shadow-md overflow-hidden md:max-w-lg m-3 flex flex-col items-center text-center ">
            <Card className="w-full justify-center">
              <CardHeader floated={false} className="h-80 w-3/4 mx-auto">
                <img
                  src={deliver}
                  alt="delivery"
                  className="object-cover w-full h-full"
                />
              </CardHeader>
              <CardBody>
                <Typography className="mb-2 text-center text-black text-xl font-bold">
                  Deliver to your customer
                </Typography>
                <Typography className="text-justify font-normal text-black">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Quisquam, voluptatum! Lorem ipsum dolor sit amet consectetur
                  adipisicing elit.
                </Typography>
              </CardBody>
            </Card>
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  );
}

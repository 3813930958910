import React from "react";

import Background from "../../Assets/AboutUs/background-aboutus.webp";
import { Link } from "react-router-dom";

const HeroSection = () => {
  return (
    <section
      style={{
        background: `url(${Background})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <div className="w-11/12 mx-auto xl:w-10/12 max-w-[1600px] h-[76vh] pt-20 pb-28 flex flex-col justify-center gap-y-6">
        <h1 className='lg:text-[56px] lg:leading-10 md:text-5xl text-3xl font-medium text-[#FFFFFF] font-["Poppins"] stroke-red-500 '>
          We make
          <span className=" font-extrabold px-3">solar power</span>
          work for you
        </h1>
        <h2 className="md:text-2xl text-lg font-medium text-[#FFFFFF] ">
          Empowering communities with sustainable energy solutions, our solar
          services harness the power of the sun to illuminate a brighter and
          cleaner future for all.
        </h2>
        <div className="mt-5">
          <Link
            to={"/contact-us"}
            className='font-["Poppins"] font-medium bg-white px-6 py-[10px] transition-all duration-300 ease-linear hover:bg-[#79c44f] text-black rounded-full'
          >
            Get a quote
          </Link>
        </div>
      </div>
    </section>
  );
};
export default HeroSection;

import React from "react";
import {
  Timeline,
  TimelineItem,
  TimelineConnector,
  TimelineHeader,
  TimelineIcon,
  TimelineBody,
} from "@material-tailwind/react";

import {
  LeagacyCard2,
  LeagacyCard1,
} from "../../LandingPage/OurLegacy/OurLegacy";

import Line from "../../../Assets/SVG_images/Line.svg";
import Icon1 from "../../../Assets/SVG_images/construction-icon.svg";
import Engineers from "../../../Assets/SVG_images/engineers.svg";

const timelineArray = [
  {
    title: "Solar Panel Installation",
    desc: "This service involves the professional installation of solar panels on rooftops or designated areas to harness sunlight and convert it into electricity.",
    icon: Icon1,
  },
  {
    title: "Battery Solutions",
    desc: "This service involves the professional installation of solar panels on rooftops or designated areas to harness sunlight and convert it into electricity.",
    icon: Icon1,
  },
  {
    title: "Energy Audits",
    desc: "This service involves the professional installation of solar panels on rooftops or designated areas to harness sunlight and convert it into electricity.",
    icon: Icon1,
  },
  {
    title: "Maintenance & Support",
    desc: "This service involves the professional installation of solar panels on rooftops or designated areas to harness sunlight and convert it into electricity.",
    icon: Icon1,
  },
];

const ourLeagacyData = [
  {
    title: "Projects Completed",
    support: "High Quality  Build Material",
    nums: 260,
    suffix: "+",
  },
  {
    title: "Satifcation Rate",
    support: "24 x 7 Live Customer Support",
    nums: 95,
    suffix: "%",
  },
  {
    title: "Satisfied Clients",
    support: "Industry Standard Installation",
    nums: 250,
    suffix: "+",
  },
  {
    title: "Oualified Engineers",
    support: "Transparent",
    nums: 100,
    suffix: "+",
  },
];

const OurService = () => {
  return (
    <section className="max-w-[1600px] mx-auto w-11/12 xl:w-10/12 md:py-20 py-12">
      <div className="flex  justify-center items-center gap-x-6 mt-6">
        <img src={Line} alt="line" className="md:w-40 w-14" />
        <h2 className='lg:text-4xl md:text-3xl text-2xl font-["Poppins"] font-normal'>
          Our Services
        </h2>
        <img src={Line} alt="line" className="md:w-40 w-14" />
      </div>
      <div className="my-14 md:flex-row flex-col flex justify-between items-start">
        <div className="md:w-5/12 w-11/12 mx-auto md:mx-0">
          <img src={Engineers} alt="engineer png" />
        </div>
        <div className="md:w-1/2 w-full">
          {timelineArray?.map((ele, i) => (
            <TimelineCard key={ele.title} {...ele} index={i} />
          ))}
        </div>
      </div>
      <div className="md:my-16 mt-10">
        <div className=" lg:hidden grid grid-cols-2 mt-8 gap-y-6">
          {ourLeagacyData?.map((el, i) => (
            <LeagacyCard2 {...el} key={i} />
          ))}
        </div>
        <div className=" lg:flex justify-between grid grid-cols-2 mt-8 gap-y-4">
          {ourLeagacyData?.map((el, i) => (
            <LeagacyCard1 {...el} key={i} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default OurService;

const TimelineCard = ({ title, desc, icon, index }) => (
  <Timeline className="">
    <TimelineItem>
      {index !== timelineArray.length - 1 && <TimelineConnector />}
      <TimelineHeader>
        <TimelineIcon className="p-0 w-7 h-7 bg-transparent">
          <img src={icon} alt={`'icon${index + 1}`} />
        </TimelineIcon>
        <h5 className="md:text-xl text-lg">{title}</h5>
      </TimelineHeader>
      <TimelineBody className="md:pb-8 pb-3">
        <p className="font-normal md:text-base text-sm">{desc}</p>
      </TimelineBody>
    </TimelineItem>
  </Timeline>
);

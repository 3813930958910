import React from "react";
import { ImCheckboxChecked } from "react-icons/im";

const cardArray = [
  {
    title: "Premium Quality Components",
    desc: "We source and install top-tier solar components known for their durability, efficiency, and performance.",
    bgColor: "#4F79C4",
  },
  {
    title: "Technical Expertise",
    desc: "Our team knows the ins and outs of solar technology, ensuring that every system is installed neatly and correctly.",
    bgColor: "#79C44F",
  },
  {
    title: "Swift Delivery and Installation",
    desc: "We work diligently to have your system up & running in no time. Choosing us means a quick & hassle-free solar installation.",
    bgColor: "#C44F79",
  },
];

const WhyChoose = () => {
  return (
    <section className="max-w-[1600px] mx-auto w-11/12 xl:w-10/12 py-10">
      <div className="md:w-10/12 mx-auto">
        <h2 className="xl:text-5xl md:text-3xl text-2xl text-center md:text-left">
          Why Choose Ranas Enterprises
        </h2>
        <p className="md:text-lg mt-4 text-center md:text-left">
          At Ranas Enterprises, our commitment to excellence, unparalleled
          expertise, and unwavering dedication set us apart. Here’s why you
          should choose us:
        </p>
      </div>
      <div
        data-aos="fade-right"
        data-aos-duration="300"
        data-aos-offset="200"
        data-aos-delay="200"
        className="hover:cursor-context-menu grid grid-cols-1 md:grid-cols-3 gap-4 mt-14"
      >
        {cardArray?.map((ele, i) => (
          <div
            style={{
              background: ele.bgColor,
            }}
            className={` p-5 rounded-xl flex flex-col gap-y-3 transition-all duration-300 ease-linear md:hover:scale-105`}
            key={ele.title}
            // data-aos="fade-right"
            // data-aos-duration="300"
            // data-aos-delay={i * 200}
          >
            <div>
              <ImCheckboxChecked
                size={25}
                className="rounded-full text-white"
              />
            </div>
            <p className="font-semibold text-lg">{ele.title}</p>
            <p className="text-medium">{ele.desc}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default WhyChoose;

import React from "react";
import Herosection from "../Components/ServicePage/HeroSection/Herosection";
import OurService from "../Components/ServicePage/OurService/OurService";
// import OurTopEngineer from "../Components/ServicePage/OurTopEngineer/OurTopEngineer";
import NewNavbar from "../Components/Navbar/NewNavbar";
import Testimonial from "../Components/LandingPage/Testimonial/Testimonial";
import Customers from "../Components/ServicePage/Customers/Customers";
import WeWork from "../Components/ServicePage/WeWork/WeWork";

const ServicesPage = () => {
  return (
    <main>
      <NewNavbar />
      <Herosection />
      <OurService />
      <WeWork />
      {/* <OurTopEngineer /> */}
      <Testimonial
        heading={"Here’s what our clients have to say"}
        subHeading={
          "Listen to delighted clients who’ve experienced Ranas Enterprises exceptional craftsmanship and professionalism"
        }
        textAlignment="center"
        zoomAnimation={false}
      />
      <Customers />
    </main>
  );
};

export default ServicesPage;

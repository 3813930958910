import React from "react";
import { Link } from "react-router-dom";

const ContactUs = () => {
  return (
    <section className="max-w-[1600px] w-11/12 mx-auto xl:w-10/12 bg-[#79C44F] my-10 md:my-16 px-[5%] py-10 rounded-3xl">
      <h3 className="font-bold md:font-normal md:text-3xl xl:text-5xl text-2xl">
        Want to get Solar Panels Installed?
      </h3>
      <h4 className="md:text-lg mt-5">
        Discover how Ranas Enterprises can transform your solar vision into a
        vibrant reality through our expertise and unwavering dedication to
        excellence.
      </h4>
      <div className="mt-8">
        <Link
          to={"/contact-us"}
          className="bg-black rounded-full text-lg text-white px-4 py-2"
        >
          Contact Us
        </Link>
      </div>
    </section>
  );
};

export default ContactUs;

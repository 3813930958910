import React from "react";
import { HiBuildingOffice2 } from "react-icons/hi2";
import { FaPhoneAlt } from "react-icons/fa";
import { BiLogoGmail } from "react-icons/bi";
// import Rooftop from "../../../Assets/rooftop_work.webp";
import Pioneering from "./Pioneering";
const Address = () => {
  return (
    <section className="md:flex justify-between items-center">
      <div>
        <div>
        <div className="w-full md:w-1/2 md:pl-8 p-4 bg-white rounded-lg flex flex-col justify-start items-start font-semibold sm:block md:hidden">
        <p className="mb-2 flex items-center text-wrap text-sm">
          <HiBuildingOffice2 className="mr-2 text-customGreen md:text-2xl text-5xl" />
          <a href="https://maps.app.goo.gl/FHzWu6wVGxigfHuQA" target="https://maps.app.goo.gl/FHzWu6wVGxigfHuQA" rel="noopener noreferrer">
            Shop No 5, Royal Marigold Apartment, Madhav Nagri, Isasani, Maharashtra 441110
          </a>
        </p>
        <p className="mb-2 flex items-center text-sm">
          <FaPhoneAlt className="mr-2 text-customGreen text-2xl" />
          <a href="tel:+918806112616">+91 8806112616</a>
        </p>
        <p className="mb-2 flex items-center text-sm">
          <BiLogoGmail className="mr-2 text-customGreen text-2xl" />
          <a href="mailto:ranas.solar@gmail.com">ranas.solar@gmail.com</a>
        </p>
      </div>
        </div>
        <iframe
            title="Google Map"
            className="w-full h-[450px] md:h-[500px] lg:h-[550px] border-none rounded-lg shadow-lg xl:hidden sm:block"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3722.6130328614895!2d78.99613987477082!3d21.08811228573829!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bd4956b43ffffff%3A0x61a8472bcd9e9eee!2sRanas%20Enterprises!5e0!3m2!1sen!2sin!4v1706267646282!5m2!1sen!2sin"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        {/* <img
          src={Rooftop}
          alt="rooftop-work"
          className="w-3/4 md:w-1/2 object-cover rounded-lg  mx-auto hidden "
        /> */}
      </div>
      
      <div className="w-full md:pl-8 p-4 justify-between items-start font-semibold hidden xl:block ">
      
      <div className="flex justify-center items-center space-x-4">
            <div className="flex items-center mr-24">
              <HiBuildingOffice2 className="mr-2 text-customGreen md:text-2xl text-6xl" />
              <a href="https://maps.app.goo.gl/FHzWu6wVGxigfHuQA" target="_blank" rel="noopener noreferrer">
              Shop No 5, Royal Marigold Apartment, Madhav Nagri, Isasani, Maharashtra 441110
            </a>
            </div>

            <div className="flex items-center">
              <FaPhoneAlt className="mr-2 text-customGreen text-2xl" />
              <a href="tel:+918806112616">+91 8806112616</a>
            </div>

            <div className="flex items-center">
              <BiLogoGmail className="mr-2 text-customGreen text-2xl" />
              <a href="mailto:ranas.solar@gmail.com">ranas.solar@gmail.com</a>
            </div>
          </div>
          <Pioneering />
      </div>
    </section>
  );
};

export default Address;

import React from "react";
import { Navbar, IconButton, Collapse } from "@material-tailwind/react";
import logo from "../../Assets/Logo1.png";
// import { Link, useLocation, useParams } from "react-router-dom";
import { Link, useLocation } from "react-router-dom";

function NewNavbar() {
  const [openNav, setOpenNav] = React.useState(false);
  const location = useLocation();
  // console.log("PPPPP",location.pathname)

  React.useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setOpenNav(false)
    );
  }, []);

  const navList = (
    <ul className="mt-2 mb-4 flex flex-col font-medium text-lg gap-2 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-6 lg:bg-inherit bg-white text-black lg:p-0 p-4">
      <Link to="/">Home</Link>
      <Link to="/products">Products</Link>
      <Link to="/project">Projects</Link>
      <Link to="/services">Services</Link>
      <Link to="/contact-us">Contact Us</Link>
    </ul>
  );

  return (
    <div className=" max-w-[1600px] lg:w-10/12 w-11/12 mx-auto  py-3">
      <Navbar className="sticky top-0 z-10 h-16 bg-inherit backdrop-saturate-100 shadow-none backdrop-blur-0 bg-opacity-0 !border-none rounded-none items-center justify-between !p-0">
        <div className="flex items-center justify-between text-black h-16">
          <Link to="/">
            <img src={logo} alt="" className="md:w-44 w-36 lg:-ml-2 -ml-1" />
          </Link>
          <div className="mr-4 hidden lg:block">{navList}</div>
          <div className="flex items-center gap-4 justify-between ">
            <div className="flex items-center gap-x-1 ">
              <Link to="/contact-us">
                <button
                  className={`lg:py-[10px] lg:px-7 py-2 px-4 md:text-lg text-base bg-black text-white rounded-full font-medium `}
                >
                  Get in touch
                </button>
              </Link>
            </div>
            <IconButton
              variant="text"
              className="ml-auto h-6 w-6 text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden"
              ripple={false}
              onClick={() => setOpenNav(!openNav)}
            >
              {openNav ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  className="h-7 w-7"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                  color={`{location.pathname=="/"?"bg-white":"bg-black text-white}`}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-7 w-7"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                  color={`{location.pathname=="/"?"bg-white":"bg-black text-white}`}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              )}
            </IconButton>
          </div>
        </div>
        <Collapse open={openNav}>{navList}</Collapse>
      </Navbar>
    </div>
  );
}
export default NewNavbar;

import React from "react";

import Carousel from "./Carousel";

import ResusePower from "../../../Assets/Solar_palm.svg";

const WeWork = () => {
  return (
    <section className="">
      <div className="w-full md:flex-row flex-col flex items-stretch max-w-[1660px] mx-auto bg-[#E4F4DA]">
        <div className="lg:w-1/2 md:w-8/12 flex items-center justify-center flex-col">
          <h1 className="font-bold text-2xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-6xl">
            How We Work
          </h1>
          <img
            src={ResusePower}
            alt="PowerResuse"
            className="w-3/4 object-cover rounded-xl"
          />
        </div>
        <div className="lg:w-1/2 md:w-8/12 py-4 px-5 flex justify-center items-center hover:cursor-context-menu ">
          <Carousel />
        </div>
      </div>
    </section>
  );
};

export default WeWork;

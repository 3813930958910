import React from "react";
import NewNavbar from "../Components/Navbar/NewNavbar";
import Herosection from "../Components/ProjectPage/Herosection";
import Service from "../Components/ProjectPage/Service";
import Contactus from "../Components/ProjectPage/Contactus";

const ProjectPage = () => {
  return (
    <main>
      <NewNavbar />
      <Herosection />
      <Service />
      <Contactus />
    </main>
  );
};

export default ProjectPage;

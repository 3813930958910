import React from 'react'
// import Slider from "react-slick";
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Autoplay } from 'swiper/modules'

import 'swiper/css/navigation'

// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

import Client1 from '../../../Assets/People/Client/Subh-sahu.svg'
import Client2 from '../../../Assets/People/Client/vikaram-singh.svg'
import Client3 from '../../../Assets/People/Client/Harsh-roy.svg'
import Client4 from "../../../Assets/People/Client/Isha-Shankar.png"

import doubleQuote from '../../../Assets/double-quote.svg'

const cardArray = [
    {
        img: Client1,
        name: "Vivek Shukla",
        designation: "",
        desc: "Got a 2kW solar panel system installed in January and have been using it since. It generates more than 25 units and has been functioning well. Electricity bills have already zeroed. I would definitely recommend installing a solar panel system on your terrace if you have the space."
        

    },
    {
        img: Client4,
        name: "Isha Shankar",
        designation: "",
        desc: "Thank you for offering such an excellent product and providing outstanding service. Solar Installation was incredibly stress-free. Rana Enterprises serves as a one-stop solution for all solar requirements.",

    },
    {
        img: Client3,
        name: "Manan Goel",
        designation: "",
        desc: "Got some great insights about solar energy and its benefits and the team at Ranas Enterprises helped me to understand the economical reason to go solar. I discussed with my father and finally we got 5kW Rooftop solar for my house.",

    },
    {
        img: Client2,
        name: "Harsh Roy",
        designation: "",
        desc: "I had a 2kW solar panel system set up in January and have been utilizing it ever since. It produces over 25 units and operates efficiently. My electricity bills have already been completely eliminated. If you have the available rooftop space, I would certainly advocate for the installation of a solar panel system."

    },
    
]

const Testimonial = ({ heading, subHeading, textAlignment = "left", zoomAnimation }) => {

    const settings = {
        className: "center",
        centerMode: true,
        infinite: true,
        centerPadding: "60px",
        slidesToShow: 2,
        speed: 500
    };
    return (
        <section className='max-w-[1600px] mx-auto w-11/12  lg:mt-12 mt-0'>
            <div className="md:w-10/12 mx-auto">
                <h2 className={`xl:text-5xl md:text-3xl text-2xl text-center md:text-${textAlignment}`}>
                    {heading}
                </h2>
                <p className={`md:text-lg mt-4 text-center md:text-${textAlignment}`}>
                    {subHeading}
                </p>
            </div>
            <div>
                <Swiper
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false
                    }}
                    modules={[Navigation, Autoplay]}
                    navigation
                    centeredSlides
                    loop
                    breakpoints={{
                        240: {
                            slidesPerView: 1,
                            spaceBetween: 60,
                        },
                        768: {
                            slidesPerView: 2,
                            spaceBetween: 40,
                        },
                        1024: {
                            slidesPerView: 3,
                            spaceBetween: 60,
                        },
                    }}
                    className='mySwiper !py-20 !px-14 '
                >
                    {/* <Slider {...settings}> */}
                    {
                        cardArray?.map((ele) => <SwiperSlide key={ele.name}>
                            <TestimonialCard  {...ele} zoomAnimation={zoomAnimation} />
                        </SwiperSlide>
                        )
                    }
                    {/* </Slider> */}
                </Swiper>
            </div>

        </section>
    )
}

export default Testimonial

const TestimonialCard = ({ name, img, desc, designation, zoomAnimation }) => (
    <div className='pt-20'>
        <div
            className={`border-[#79C44F]  bg-[#F7F6F4] flex justify-center items-center rounded-lg flex-col px-5 xl:px-10 active__background shadow-xl ${zoomAnimation ? "active__background__zoom" : ""}`}
        >
            <div className='relative flex justify-center items-center flex-col -top-16'>
                <img src={img} alt={name} className=' aspect-square w-32' />
                <p className='font-semibold text-xl'>{name}</p>
                <p className='text-black/40 font-medium text-lg'>{designation}</p>
            </div>
            <div className='relative flex justify-center items-center flex-col gap-y-2 -top-12'>
                <div className='self-start'>
                    <img src={doubleQuote} alt="double quote" />
                </div>
                <div className='h-[145px] text-sm sm:text-base'>
                    <p className=' line-clamp-6'>
                        {desc}
                    </p>
                </div>
                <div className='self-end'>
                    <img src={doubleQuote} alt="double quote inverse" className='rotate-180' />
                </div>
            </div>

        </div>
    </div>
)
import React from "react";

import Acc from "../../../Assets/SVG_images/customer/acc.svg";
import Adani from "../../../Assets/SVG_images/customer/adani.svg";
import Exide from "../../../Assets/SVG_images/customer/exide.svg";
import Lt from "../../../Assets/SVG_images/customer/lt.svg";
import Tata from "../../../Assets/SVG_images/customer/tata.svg";

const customerArray = [
  {
    img: Acc,
    alt: "ACC",
  },
  {
    img: Tata,
    alt: "Tata",
  },
  {
    img: Exide,
    alt: "Exide",
  },
  {
    img: Lt,
    alt: "LT",
  },
  {
    img: Adani,
    alt: "Adani",
  },
];

const Customers = () => {
  return (
    <>
      <section className="bg-[#6DDE2D] bg-opacity-20 py-10">
        <h2 className="md:text-3xl text-2xl font-semibold text-center">
          Corporate Customers
        </h2>
        <div className="max-w-[1600px] mx-auto w-11/12 xl:w-10/12 flex mt-7 items-center justify-center gap-x-3">
          {customerArray?.map((ele) => (
            <div key={ele.alt}>
              <img src={ele.img} alt={ele.alt} className="w-40 h-32" />
            </div>
          ))}
        </div>
      </section>
      <h4 className="md:text-2xl text-xl text-center font-semibold py-8 justify-center items-center">
        Get ready to embark on your journey to clean, renewable energy now
      </h4>
    </>
  );
};

export default Customers;

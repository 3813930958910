import React from 'react'
import Herosection from '../Components/ProductsPage/Herosection/Herosection'
import NewNavbar from '../Components/Navbar/NewNavbar'
import ProductList from '../Components/ProductsPage/ProductList/ProductList'
import ProductVideos from '../Components/ProductsPage/ProductVideos/ProductVideos'
import WhyChoose from '../Components/LandingPage/WhyChoose/WhyChoose'
import ProductWhyChoose from '../Components/ProductsPage/ProductWhyChoose/ProductWhyChoose'

const ProductsPage = () => {
  return (
    <div className=''>
        <NewNavbar />
        <Herosection/>
        <ProductList/>
        <ProductVideos/>
        <ProductWhyChoose/>
      
    </div>
  )
}

export default ProductsPage

import React from "react";
import { Route, Routes } from "react-router-dom";
import LandingPage from "../Pages/LandingPage";
import ServicesPage from "../Pages/ServicesPage";
import ContactUsPage from "../Pages/ContactUsPage";
import ProductsPage from "../Pages/ProductsPage";

import ProjectPage from "../Pages/ProjectPage";
import TermsnConditions from "../Pages/TermsnConditions";
import PrivacyPolicy from "../Pages/PrivacyPolicy";


import NewContactUsPage from "../Pages/NewContactUsPage";

const MainRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/services" element={<ServicesPage />} />
      <Route path="/contact-us" element={<NewContactUsPage />} />
      <Route path="/project" element={<ProjectPage />} />
      <Route path="/products" element={<ProductsPage />} />
      <Route path="/tnc" element={<TermsnConditions />} />
      <Route path="/privacy" element={<PrivacyPolicy />} />
    </Routes>
  );
};
export default MainRoutes;

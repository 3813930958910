import React from "react";
import { ImCheckboxChecked } from "react-icons/im";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";

const ourLeagacyData = [
  {
    title: "Projects Completed",
    support: "High Quality  Build Material",
    nums: 260,
    suffix: "+",
  },
  {
    title: "Satifcation Rate",
    support: "24 x 7 Live Customer Support",
    nums: 95,
    suffix: "%",
  },
  {
    title: "Satisfied Clients",
    support: "Industry Standard Installation",
    nums: 250,
    suffix: "+",
  },
  {
    title: "Oualified Engineers",
    support: "Transparent",
    nums: 100,
    suffix: "+",
  },
];

const OurLegacy = () => {
  return (
    <header className=" max-w-[1600px] bg-[#EAF3F5] md:py-20 py-12">
      <div className=" lg:w-10/12 w-11/12 mx-auto">
        <div className=" lg:w-10/12 md:w-11/12 w-full mx-auto">
          <h1 className=" lg:text-5xl md:text-3xl text-2xl lg:text-left text-center">
            Our Legacy of Excellence
          </h1>
          <p className=" md:text-lg mt-5 lg:text-left text-center">
            We are Ranas Enterprises your dependable partner in the solar energy
            landscape. We illuminate your green energy aspirations, turning
            solar visions into a bright reality.
          </p>
        </div>
        <div className=" lg:hidden grid grid-cols-2 mt-8 gap-y-6">
          {ourLeagacyData?.map((el, i) => (
            <LeagacyCard2 {...el} key={i} />
          ))}
        </div>
        <div className=" lg:flex justify-between grid grid-cols-2 mt-8 gap-y-4">
          {ourLeagacyData?.map((el, i) => (
            <LeagacyCard1 {...el} key={i} />
          ))}
        </div>
      </div>
    </header>
  );
};

export default OurLegacy;

export const LeagacyCard1 = ({ title, nums, support, suffix }) => {
  const [viewPortEntered, setViewPortEntered] = React.useState(false);

  return (
    <VisibilitySensor
      partialVisibility
      onChange={(isVisible) => {
        if (isVisible) {
          setViewPortEntered(true);
        }
      }}
    >
      <div className=" flex flex-col items-center gap-y-6 ">
        <div className=" text-center lg:block hidden">
          <h4 className=" text-3xl font-semibold">
            {viewPortEntered && (
              <CountUp start={0} end={nums} duration={3} suffix={suffix} />
            )}
          </h4>
          <p>{title}</p>
        </div>
        <div className=" 2xl:w-[15.5rem] xl:w-[15rem] lg:w-52 md:w-56 w-[9rem] md:h-20 h-16 flex md:gap-x-3 gap-x-2 justify-start items-center rounded-full bg-black md:px-6 px-3 ">
          <div className=" md:w-6 md:h-6 w-5 h-5">
            <ImCheckboxChecked className="md:text-xl text-white rounded-full" />
          </div>
          <p className="text-white text-xs md:text-sm lg:text-[15px] xl:text-base ">
            {support}
          </p>
        </div>
      </div>
    </VisibilitySensor>
  );
};

export const LeagacyCard2 = ({ title, nums, suffix }) => {
  const [viewPortEntered, setViewPortEntered] = React.useState(false);

  return (
    <VisibilitySensor
      partialVisibility
      onChange={(isVisible) => {
        if (isVisible) {
          setViewPortEntered(true);
        }
      }}
    >
      <div className=" flex flex-col items-center gap-y-6 ">
        <div className=" text-center">
          <h4 className=" md:text-3xl text-2xl font-semibold ">
            {viewPortEntered && (
              <CountUp start={0} end={nums} duration={3} suffix={suffix} />
            )}
          </h4>
          <p>{title}</p>
        </div>
        {/* <div className='w-64 h-20 flex gap-x-3 justify-start items-center rounded-full bg-black px-6'>
                <div className=' w-6 h-6'><ImCheckboxChecked size={20} className=' text-white rounded-full' /></div>
                <p className='text-white'>{support}</p>
            </div> */}
      </div>
    </VisibilitySensor>
  );
};

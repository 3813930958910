import React from "react";

import NewNavbar from "../../Navbar/NewNavbar";
import HeroImage from "../../../Assets/Hero.webp";

import Aos from "aos";
import "aos/dist/aos.css";

Aos.init();


const Herosection = () => {
  return (
    <div className="relative">
      <img
        src={HeroImage}
        alt="heroimage"
        className="w-full lg:h-[100vh] h-screen object-cover"
      />
      <header className="max-w-[1600px] mx-auto h-[100vh] w-full absolute top-0 left-0 ">
        {/* <TopContactDetails /> */}
        {/* <Navbar /> */}
        {/* <NewNavbar /> */}
        <div className=" lg:w-9/12 w-11/12 m-auto lg:mt-24 md:mt-16 mt-12">
          <h1
            data-aos="fade-right"
            data-aos-duration="1000"
            data-aos-offset="400"
            data-aos-delay="100"
            className=" lg:text-7xl md:text-5xl text-3xl font-medium"
          >
            Illuminating Your Future with Solar Solutions.
          </h1>
          <p
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-offset="200"
            data-aos-delay="200"
            className="mt-8 lg:text-xl  font-medium"
          >
            We are Ranas Enterprises, With a legacy of sustainable excellence
            and an unwavering commitment to quality, we transform sunlight into
            power, turning your green energy visions into reality.
          </p>
          <button
            data-aos="flip-up"
            data-aos-duration="1000"
            data-aos-offset="200"
            data-aos-delay="200"
            className=" mt-10 py-1 px-5 bg-white rounded-full font-medium h-10"
          >
            Learn more
          </button>
          
        </div>
        
      </header>
      
    </div>
    
  );
};

export default Herosection;

import React from "react";
import Image1 from "../../Assets/AboutUs/carbon_energy-renewable.webp";
import Image2 from "../../Assets/AboutUs/ph_plant.webp";
import Image3 from "../../Assets/AboutUs/solar_hand-money-outline.webp";

const pioneeringData = [
  {
    img: Image1,
    desc: "Sustainable and renewable and environmentally friendly energy source",
  },
  {
    img: Image2,
    desc: "Green energy source producing minimal greenhouse gas emissions as to fossil fuels",
  },
  {
    img: Image3,
    desc: "Reduces reliance on grid power lead to significant savings on electricity bills",
  },
];
const Pioneering = () => {
  return (
    <header className=" max-w-[1600px] w-11/12 lg:w-10/12 mx-auto lg:py-20 py-14">
      <h2 className=" lg:text-4xl text-2xl font-semibold text-center">
        Pioneering Solar Excellence: Our Vision in Action
      </h2>

      <section className=" grid md:grid-cols-3 grid-cols-1 justify-center lg:gap-16 md:gap-8 gap-6  md:mt-12 mt-8">
        {pioneeringData?.map((el, i) => (
          <div
            className=" flex justify-start items-start flex-col text-center md:text-left "
            key={i}
          >
            <img
              src={el.img}
              alt="icon"
              className=" w-14 h-16 self-center md:self-start"
            />
            <p className=' lg:text-xl  !leading-6 font-medium mt-3 font-["Poppins"]'>
              {el.desc}
            </p>
          </div>
        ))}
      </section>
    </header>
  );
};

export default Pioneering;

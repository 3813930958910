import React from "react";

import Background from "../../Assets/background/project-page-background.webp";
import { Link } from "react-router-dom";

const Herosection = () => {
  return (
    <section
      style={{
        background: `url(${Background})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <div className="w-11/12 mx-auto  max-w-[1600px] h-[76vh] pt-20 pb-28 flex flex-col justify-center gap-y-6">
        <h1 className="lg:text-[56px] lg:leading-10 md:text-5xl text-3xl font-semibold text-[#FFFFFF]  stroke-red-500 ">
          Discover the projects that we’re proud of
        </h1>
        <h1 className="md:text-2xl text-lg font-medium text-[#FFFFFF] ">
          Welcome to our Solar Projects Showcase, where innovation meets
          sustainability. Explore how our solar devices are making a positive
          impact on diverse projects worldwide.
        </h1>
        <div className="mt-5">
          <Link
            to={"/contact-us"}
            className='font-["Poppins"] font-medium bg-white px-6 py-[10px] text-black rounded-full'
          >
            Get a quote
          </Link>
        </div>
      </div>
    </section>
  );
};
export default Herosection;

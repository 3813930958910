import React from "react";
import { IoMdArrowForward } from "react-icons/io";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Pagination } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";

// import required modules

import Solar from "../../../Assets/LandingPage/Card/solar.webp";
import Switch from "../../../Assets/LandingPage/Card/switch-boxs.webp";
import Solar2 from "../../../Assets/LandingPage/Card/solar2.webp";
import { Link } from "react-router-dom";

const cardArray = [
  {
    title: "Design and Planning Services",
    desc: "Our expert design and planning services ensure meticulous  execution",
    img: Solar,
  },
  {
    title: "Construction and Building",
    desc: "From groundbreaking to finishing touches, we bring your vision to life with precision.",
    img: Switch,
  },
  {
    title: "Renovation and Remodelling",
    desc: "Enhance exisiting spaces or rejuvenate structures with tailored solutions",
    img: Solar2,
  },
];

const SolarSolution = () => {
  return (
    <section className="max-w-[1600px] mx-auto w-11/12 xl:w-10/12 mt-10">
      <div className="w-10/12 mx-auto">
        <h2 className="xl:text-5xl md:text-3xl text-2xl text-center md:text-left">
          Fast & Affordable Solar Solutions
        </h2>
        <p className="md:text-lg mt-4 text-center md:text-left">
          At Rana Solar, we offer solar solutions that are not only quick but
          also budget-friendly. Our commitment is to make the switch to solar
          easy and affordable for you.
        </p>
      </div>
      <div className="hidden  md:grid grid-cols-3 gap-4 mt-14">
        {cardArray?.map((ele) => (
          <div
            className={` p-5 rounded-xl flex flex-col gap-y-2 relative transition-all duration-300 ease-linear md:hover:scale-110`}
            key={ele.title}
          >
            <div>
              <img src={ele.img} alt="" className="object-fit w-full h-full " />
            </div>
            <div className="absolute bottom-[10%] text-white px-5">
              <p className="font-semibold lg:text-lg ">{ele.title}</p>
              <p className="text-sm lg:text-base font-medium mt-1 mb-2  lg:mt-3 lg:mb-5">
                {ele.desc}
              </p>
              <Link to={"/"} className="flex gap-x-4 items-center capitalize">
                learn more <IoMdArrowForward />
              </Link>
            </div>
          </div>
        ))}
      </div>
      <div className="md:hidden mt-5">
        <Swiper
          slidesPerView={1}
          spaceBetween={10}
          freeMode={true}
          pagination={{
            clickable: false,
          }}
          modules={[FreeMode, Pagination]}
          className="mySwiper !pb-5"
        >
          {cardArray?.map((ele) => (
            <SwiperSlide key={ele.title}>
              {" "}
              <div className={` p-5 rounded-xl flex flex-col gap-y-2 relative`}>
                <div>
                  <img
                    src={ele.img}
                    alt=""
                    className="object-fit w-full h-full "
                  />
                </div>
                <div className="absolute bottom-[10%] text-white px-5">
                  <p className="font-semibold lg:text-lg ">{ele.title}</p>
                  <p className="text-sm lg:text-base font-medium mt-1 mb-2  lg:mt-3 lg:mb-5">
                    {ele.desc}
                  </p>
                  <Link
                    to={"/"}
                    className="flex gap-x-4 items-center capitalize"
                  >
                    learn more <IoMdArrowForward />
                  </Link>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
};

export default SolarSolution;

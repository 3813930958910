import logo from "./logo.svg";
import { useEffect } from "react";
import AOS from "aos";

import "aos/dist/aos.css";

import "./App.css";
import MainRoutes from "./AllRoutes/MainRoutes";
import Footer from "./Components/Footer/Footer";
import NewFooter from "./Components/Footer/NewFooter";
// import { Helmet } from "react-helmet";
import { HelmetProvider } from 'react-helmet-async';
import { Helmet } from 'react-helmet-async';

function App() {
  useEffect(() => {
    AOS.init({
      duration: 300,
      initClassName: "initial-anim",
      once: true,
      mirror: true,
      offset: 50,
      debounceDelay: 100,
      throttleDelay: 100,
    });
  }, []);
  return (
    <div className="App ">
      <HelmetProvider>
      <Helmet>
        {/* title=’Nagpur's No. 1 Solar Company: Buy Solar Panels for Home & Business'
        description=’Buy solar panels, batteries, and inverters for residential, commercial, agricultural from a range of. 10 watts to 100 kW at Rana Enterprises, Nagpur's best solar solutions provider. Select from an array of solar panels, inverters, lithium batteries, charge controllers, and comprehensive installation kits. Benefit from our tailored solar finance options for rooftop installations.' */}
        <title>Rana Enterprises: Nagpur's No. 1 Solar Company</title>
        <meta name="description" content="Buy solar panels, batteries, and inverters for residential, commercial, agricultural from a range of. 10 watts to 100 kW at Rana Enterprises, Nagpur's best solar solutions provider. Select from an array of solar panels, inverters, lithium batteries, charge controllers, and comprehensive installation kits. Benefit from our tailored solar finance options for rooftop installations." />
        <meta name="keywords" content="soalr panels, batteries, inverters"/>
      </Helmet>
      <MainRoutes />
      <NewFooter />
      </HelmetProvider>
 
      {/* <Project/> */}
    </div>
  );
}

export default App;

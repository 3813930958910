import React, { useState } from "react";
import { Link } from "react-router-dom";
// import { Drawer } from "@material-tailwind/react";
import { IoChevronDown } from "react-icons/io5";

// import proImg1 from "../../../Assets/Products/SolarPowerbank1000mah.webp";
import proImg2 from "../../../Assets/Products/solar-power-system.webp";
import proImg3 from "../../../Assets/Products/SolarStreetLight.webp";
import proImg4 from "../../../Assets/Products/SolarModule.webp";
// import proImg5 from "../../../Assets/Products/SolarMC4connector.webp";
import proImg6 from "../../../Assets/Products/UTLInverterSystem.webp";
// import proImg7 from "../../../Assets/Products/UTLHomeUPS.webp";
// import proImg8 from "../../../Assets/Products/LightSystem.webp";
// import proImg9 from "../../../Assets/Products/SolarWaterHeater.webp";
import proImg10 from "../../../Assets/Products/agriculture-solar-water-pumping-system.webp";
import proImg11 from "../../../Assets/Products/SolarInverter.webp";

import starImg from "../../../Assets/Products/stars.webp";
import BestSellingImg from "../../../Assets/Products/BestSelling.webp";
import RightDrawer from "./RightDrawer/RightDrawer";

const productDetailsList = [
  //   {
  //     title: "Solar Power bank 1000mah",
  //     img: proImg1,
  //     price: "₹ 3,025/unit",
  //     price1: 3025,
  //     originalPrice: 5025,
  //     stars: "4",
  //     bestSelling: true,
  //   },
  {
    title: "Solar Power System",
    img: proImg2,
    price: "₹ 60,000/KW (Before Subsidy)",
    price1: 4000,
    originalPrice: 5025,
    stars: "4",
    bestSelling: false,
  },
  {
    title: "Solar Street Light",
    img: proImg3,
    price: "₹ 4,500-80,000/unit",
    price1: 4500,
    originalPrice: 5025,
    stars: "4",
    bestSelling: false,
  },
  {
    title: "Solar Module",
    img: proImg4,
    price: "₹ 6,000/unit",
    price1: 3025,
    originalPrice: 5025,
    stars: "4",
    bestSelling: false,
  },
  //   {
  //     title: "Solar MC4 connector",
  //     img: proImg5,
  //     price: "₹ 6,000/unit",
  //     price1: 6000,
  //     originalPrice: 5025,
  //     stars: "4",
  //     bestSelling: false,
  //   },
  {
    title: "UTL Inverter System",
    img: proImg6,
    price: "₹ 10,000-12,000/unit",
    price1: 5050,
    originalPrice: 6025,
    stars: "4",
    bestSelling: false,
  },
  //   {
  //     title: "UTL Home UPS",
  //     img: proImg7,
  //     price: "₹ 4,000/unit",
  //     price1: 4000,
  //     originalPrice: 5025,
  //     stars: "4",
  //     bestSelling: false,
  //   },
  //   {
  //     title: "Light System",
  //     img: proImg8,
  //     price: "₹ 4,500/unit",
  //     price1: 4500,
  //     originalPrice: 5025,
  //     stars: "4",
  //     bestSelling: false,
  //   },
  //   {
  //     title: "Solar Water Heater",
  //     img: proImg9,
  //     price: "₹ 15,000/unit",
  //     price1: 15000,
  //     originalPrice: 17025,
  //     stars: "4",
  //     bestSelling: false,
  //   },
  {
    title: "Solar Water Pumping System",
    img: proImg10,
    price: "₹ 85,000/unit",
    price1: 15000,
    originalPrice: 17025,
    stars: "4",
    bestSelling: false,
  },
  {
    title: "Solar Inverter",
    img: proImg11,
    price: "₹ 14,000-17,000/unit",
    price1: 15000,
    originalPrice: 17025,
    stars: "4",
    bestSelling: false,
  },
];

const filterByPrice = ["Under 2000", "2000-5000", "5000-8000", "8000 & above"];
const filterByProduct = [
  "Solar Panel",
  "Invertors",
  "Off grid",
  "Lights",
  "Batteries",
  "Hybrid System",
  "Agri Products",
  "Home UPS",
  "Solar Modules",
  "Solar Pumps",
  "Voltage type",
];

const ProductList = () => {
  const [filterId, setFilterId] = useState(undefined);
  const [openRight, setOpenRight] = React.useState(false);

  const openPriceDrawer = () => {
    setFilterId(1);
    setOpenRight(true);
  };
  const openProductDrawer = () => {
    setFilterId(2);
    setOpenRight(true);
  };
  const closeDrawerRight = () => {
    setOpenRight(false);
  };

  return (
    <header className=" max-w-[1600px] mx-auto md:w-10/12 w-11/12 flex lg:flex-row flex-col justify-between">
      <div className=" lg:w-[22%] w-full flex lg:flex-col lg:justify-normal justify-between ">
        <p className=" md:text-2xl text-lg font-semibold">Filters</p>
        <div className="lg:hidden self-end flex gap-x-5">
          <button
            className="flex items-center gap-x-1 font-medium text-[#79C44F]"
            onClick={openPriceDrawer}
          >
            By Price <IoChevronDown />
          </button>
          <button
            className="flex items-center gap-x-1 font-medium text-[#79C44F]"
            onClick={openProductDrawer}
          >
            By Product <IoChevronDown />
          </button>
        </div>

        <div className="lg:block hidden">
          <h4 className=" text-[#79C44F] md:text-xl text-base font-medium lg:mt-4">
            By Price
          </h4>
          <div className="lg:block hidden lg:ml-4">
            {filterByPrice?.map((el, i) => (
              <div className=" flex gap-x-4 items-center mb-1" key={i}>
                <input
                  type="checkbox"
                  value={el}
                  placeholder="Under 2000"
                  className="w-4 h-4"
                />
                <label htmlFor="" className=" text-lg">
                  {el}
                </label>
              </div>
            ))}
          </div>
        </div>
        <div className="lg:block hidden">
          <h4 className=" text-[#79C44F] md:text-xl text-base font-medium lg:mt-4">
            By Products
          </h4>

          <div className="lg:block hidden ml-4">
            {filterByProduct?.map((el, i) => (
              <div className=" flex gap-x-4 items-center mb-1" key={i}>
                <input
                  type="checkbox"
                  value={el}
                  placeholder="Under 2000"
                  className="w-4 h-4"
                />
                <label htmlFor="" className=" text-lg">
                  {el}
                </label>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className=" lg:w-[76%] w-full lg:mt-0 mt-10">
        <div className="grid lg:grid-cols-3 grid-cols-2  md:gap-6 gap-4">
          {productDetailsList?.map((el, i) => (
            <ProductCard el={el} key={i} />
          ))}
        </div>
        <div className=" flex gap-x-2 justify-center my-8">
          <button className=" border border-[#79C44F] border-solid text-[#79C44F] font-medium px-1 hover:text-white hover:bg-[#79C44F]">
            Prev
          </button>
          <button className=" border border-[#79C44F] border-solid text-[#79C44F] font-medium px-1 hover:text-white hover:bg-[#79C44F]">
            1
          </button>
          <button className=" border border-[#79C44F] border-solid text-[#79C44F] font-medium px-1 hover:text-white hover:bg-[#79C44F]">
            2
          </button>
          <button className=" border border-[#79C44F] border-solid text-[#79C44F] font-medium px-1 hover:text-white hover:bg-[#79C44F]">
            Next
          </button>
        </div>
      </div>
      <RightDrawer
        filterId={filterId}
        openRight={openRight}
        closeDrawerRight={closeDrawerRight}
      />
    </header>
  );
};
export default ProductList;

const ProductCard = ({ el }) => {
  return (
    <div className="relative group flex flex-col gap-y-1 border border-[#A7A7A7] border-solid rounded-3xl md:px-6 md:py-4 px-3 py-3 hover:shadow-lg transition-all duration-150 ease-linear">
      <img
        src={el.img}
        alt={el.title}
        className="md:w-40 md:h-36 w-20 h-16 self-center object-cover group-hover:scale-110 transition-all duration-150 ease-linear"
      />
      <h5 className="md:text-lg text-sm font-semibold line-clamp-1">
        {el.title}
      </h5>
      <img src={starImg} alt="star" className="md:h-5 h-4 self-start" />
      <div className="flex gap-x-4 md:text-lg text-sm">
        <p>Approx Price: {el.price}</p>
        {/* <p>Approx. Price: ₹{el.price1}</p>
        <p className=" line-through text-[#A7A7A7]">₹{el.originalPrice}</p> */}
      </div>
      <Link
        to={"#"}
        className="xl:w-40 lg:w-36 w-24 md:text-base text-xs text-center md:py-[6px] py-1 self-center rounded-full bg-black text-white font-semibold"
      >
        BUY NOW
      </Link>
      {el.bestSelling && (
        <img
          src={BestSellingImg}
          alt="best-selling"
          className="md:w-28 w-20 absolute md:-top-[21px] md:-left-[13px] -left-[9px] -top-[15px]"
        />
      )}
    </div>
  );
};

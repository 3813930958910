import { Button } from '@material-tailwind/react'
import React from 'react'

const ProductVideos = () => {
    return (
        <header className=' max-w-[1600px] mx-auto py-10 border-b-4 border-t-4 border-[rgb(121,196,79)] border-solid'>
        <div className=' mx-auto md:w-10/12 w-11/12 '>
            <div className=' mb-6'>
                <h1 className=' lg:text-4xl md:text-3xl text-2xl font-semibold'>Product videos</h1>
                <div className='lg:w-60 md:w-52 w-44 h-1 bg-[#79C44F]'></div>
            </div>
            <div className=' flex lg:flex-row flex-col justify-between gap-4'>
                <div className=' lg:w-1/2 w-full flex gap-x-4 border border-[#79C44F] border-solid p-4 rounded-2xl'>
                    <iframe src="https://www.youtube.com/embed/wV-G7gCp2r0?si=28xq_Gb8yuB7Km5y" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen className=' rounded-2xl md:w-56 w-28'></iframe>
                    <div>
                        <h4 className=' md:text-lg font-semibold'>On Grid Power system installation at home</h4>
                        <p className=' lg:text-base text-xs'>Lorem ipsum dolor sit amet, consetur adipiscing elit, sed do eiusmod tempor  djwinci enim ad minim veniam, quis nostrud exercitation...</p>
                        <Button className=' rounded-full lowercase lg:text-sm text-xs bg-black py-2 font-medium mt-1'>watch Now</Button>
                    </div>
                </div>
                <div className=' lg:w-1/2 w-full flex gap-x-4 border border-[#79C44F] border-solid p-4 rounded-2xl'>
                    <iframe src="https://www.youtube.com/embed/63fd9kH6Kiw?si=Ot006itGvlkEqXPq" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen className=' rounded-2xl md:w-56 w-28'></iframe>
                    <div>
                        <h4 className=' md:text-lg font-semibold'>Energy ambitions turns desert into solar powerhouse</h4>
                        <p className=' lg:text-base text-xs'>Lorem ipsum dolor sit amet, consetur adipiscing elit, sed do eiusmod tempor  djwinci enim ad minim veniam, quis nostrud exercitation...</p>
                        <Button className=' rounded-full lowercase lg:text-sm text-xs bg-black py-2 font-medium mt-1'>watch Now</Button>
                    </div>
                </div>
            </div>
            <div className=' flex lg:justify-end justify-center'>
                <button className='lg:text-2xl text-xl font-semibold text-[#79C44F] underline underline-offset-4 mt-4'>More clips</button>
            </div>
        </div>
        </header>
    )
}

export default ProductVideos

import React from 'react'

import Background from '../../../Assets/background/services-back.svg'
import { Link } from 'react-router-dom'

const Herosection = () => {
    return (
        <section
            style={{
                background: `url(${Background})`,
                backgroundPosition: "top",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
            }}
        >
            <div className='w-11/12 mx-auto xl:w-10/12 max-w-[1600px] text-white pt-20 pb-28'>
                <h1 className='md:text-3xl text-2xl font-semibold'>
                    At{" "}
                    <span className='md:text-4xl text-[1.7rem] font-extrabold text-[#79C44F]'>
                        Ranas Enterprises,
                    </span>

                </h1>
                <h2 className='md:text-3xl text-2xl font-semibold'>
                    We are dedicated to empowering you with sustainable & renewable energy solutions.

                </h2>
                <div className='mt-5'>
                    <Link to={"/"} className='font-["Poppins"] bg-white px-4 py-2 text-black rounded-full'>
                        Get Started
                    </Link>
                </div>
            </div>

        </section>
    )
}

export default Herosection
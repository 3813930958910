import React from "react";
import { IoStar } from "react-icons/io5";

import card1 from "../../Assets/ProjectPage/card1.webp";
import card2 from "../../Assets/ProjectPage/card2.webp";
import card3 from "../../Assets/ProjectPage/card3.webp";

const cardArray = [
  {
    img: card1,
    title: "Budget Friendly",
    desc: "Lorem ipsum dolor sit amet, uidgh consectetur adipiscing elit, sed do eiusmod tempor djwincididunt ut labore et dolore magna aliquat enim ad minim veniam, quis nostrud",
  },
  {
    img: card2,
    title: "Environment friendly",
    desc: "Lorem ipsum dolor sit amet, uidgh consectetur adipiscing elit, sed do eiusmod tempor djwincididunt ut labore et dolore magna aliquat enim ad minim veniam, quis nostrud",
  },
  {
    img: card3,
    title: "Best Engineers",
    desc: "Lorem ipsum dolor sit amet, uidgh consectetur adipiscing elit, sed do eiusmod tempor djwincididunt ut labore et dolore magna aliquat enim ad minim veniam, quis nostrud",
  },
];

const Contactus = () => {
  return (
    <section className="max-w-[1600px] mx-auto w-11/12 xl:w-10/12 my-20">
      <div className="flex justify-evenly lg:justify-between items-center gap-8  md:flex-row flex-col md:flex-wrap lg:flex-nowrap">
        {cardArray?.map((ele, i) => (
          <ContactCard key={i} {...ele} />
        ))}
      </div>
    </section>
  );
};

export default Contactus;

const ContactCard = ({ img, title, desc }) => (
  <div className="border border-t-4 border-customGreen p-4 rounded-2xl shadow-[0px_11px_10.8px_0px] shadow-black/25 max-w-[320px] lg:max-w-[380px]">
    <div className="flex justify-center items-center">
      <img src={img} alt={title} className="w-48 h-48" />
    </div>
    <div className="bg-customGreen p-3 text-white rounded-xl">
      <div className="flex justify-between items-center">
        <h6 className="text-xl font-bold underline">{title}</h6>
        <div>
          <IoStar color="white" />
        </div>
      </div>
      <p className="text-[#EDEDED]">{desc}</p>
    </div>
  </div>
);

import React, { useState, useEffect } from "react";
import Modal from "react-modal";

import Herosection from "../Components/LandingPage/Herosection/Herosection";
import WhyChoose from "../Components/LandingPage/WhyChoose/WhyChoose";
import SolarSolution from "../Components/LandingPage/SolarSolution/SolarSolution";
import Products from "../Components/LandingPage/Products/Products";
import Project from "../Components/LandingPage/Project/Project";
import OurLegacy from "../Components/LandingPage/OurLegacy/OurLegacy";
import Testimonial from "../Components/LandingPage/Testimonial/Testimonial";
import ContactUs from "../Components/LandingPage/ContactUs/ContactUs";
import NewNavbar from "../Components/Navbar/NewNavbar";

import { AiOutlineClose } from "react-icons/ai";

const LandingPage = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setModalIsOpen(true);
    }, 15000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="relative z-50">
      <NewNavbar />
      <Herosection />
      <OurLegacy />
      <WhyChoose />
      <SolarSolution />
      <Products />
      <Project />
      <Testimonial
        heading={"Client Voices of Satisfaction"}
        subHeading={
          "Listen to delighted clients who’ve experienced Ranas Enterprises exceptional craftsmanship and professionalism"
        }
        zoomAnimation
      />
      <ContactUs />
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        className="fixed inset-0 flex items-center justify-center z-50 outline-none focus:outline-none "
        overlayClassName="fixed inset-0 z-50 backdrop-blur"
        contentLabel="Contact Form"
      >
        <button
          onClick={() => setModalIsOpen(false)}
          className="text-white bg-red-500 hover:bg-red-900 rounded-full p-2 absolute top-2 right-2"
        >
          <AiOutlineClose size={24} />
        </button>
        <iframe
          title="Popup Contact Form"
          aria-label="Contact Form - Ranas Enterprises"
          frameborder="0"
          className="w-full sm:w-[75vw] md:w-[50vw] lg:w-[30vw] h-[75vh] border-none rounded-2xl"
          src="https://forms.zohopublic.in/gbjbuzz/form/ContactFormRanasEnterprises/formperma/UYnbh5U-8LQQXg_xuOaquMatf1kl7wil4WXZ0tDghtQ"
        ></iframe>
      </Modal>
    </div>
  );
};

export default LandingPage;

import React from "react";
import { Link } from "react-router-dom";
import { HiOutlineBuildingOffice2 } from "react-icons/hi2";
import { IoMdCall, IoMdMail } from "react-icons/io";
import Logo from "../../Assets/Logo2.png";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedin,
  FaMedium,
  FaYoutube,
  FaSquareXTwitter,
} from "react-icons/fa6";

const desktopLinks = [
  [
    {
      title: "Projects",
      icon: "",
      link: "/projects",
    },
    {
      title: "Products",
      icon: "",
      link: "/products",
    },
    {
      title: "Services",
      icon: "",
      link: "/services",
    },
  ],
  [
    {
      title: "Contact Us",
      icon: "",
      link: "/contact-us",
    },
    {
      title: "Terms & Conditions",
      icon: "",
      link: "/tnc",
    },
    {
      title: "Privacy Policy",
      icon: "",
      link: "/privacy",
    },
  ],
];

const contactInfoLinks = [
  {
    title: "Nagpur,Maharashtra",
    icon: <HiOutlineBuildingOffice2 />,
    link: "",
  },
  {
    title: "+918806112616",
    icon: <IoMdCall />,
    link: "tel:+918806112616",
  },
  {
    title: "ranas.solar@gmail.com",
    icon: <IoMdMail />,
    link: "mailto:ranas.solar@gmail.com",
  },
];

const Footer = () => {
  return (
    <footer className="bg-black  text-white">
      <div className="pb-16 pt-4 sm:pt-10 lg:pt-12">
        <div className="mx-auto max-w-screen-2xl px-4 md:px-8">
          <div className="grid grid-cols-2 gap-12 pt-10 md:grid-cols-4 lg:grid-cols-6 lg:gap-8 lg:pt-12">
            <div className="flex flex-col xl:flex-row col-span-full lg:col-span-3">
              <div className="mb-4 lg:-mt-2 ">
                <img src={Logo} alt="logo" className="w-48 m-10" />
              </div>
              <p className="text-white-500 sm:pr-8 md:mx-20 xl:border-r-2 border-spacing-8">
                We “Ranas Enterprises” are a Sole Proprietor firm, engaged as
                the foremost Wholesaler Of Solar-based Products
                <div className="hidden md:flex items-center gap-4 mt-4 space-x-4">
                  <FaFacebookF size={24} />
                  <FaSquareXTwitter size={24} />
                  <FaInstagram size={24} />
                  <FaLinkedin size={24} />
                  <FaMedium size={24} />
                  <FaYoutube size={24} />
                </div>
              </p>
            </div>
            <div>
              <nav className="flex flex-col md:flex-row gap-4">
                <div className="flex flex-col md:flex-row gap-4 items-start">
                  {desktopLinks.map((group, index) => (
                    <div key={index}>
                      {group.map((link, linkIndex) => (
                        <div key={linkIndex}>
                          <a href={link.link} className="text-white-500 mr-24" style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                            {link.title}
                          </a>
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
                <div>
                  {contactInfoLinks.map((link, index) => (
                    <div key={index} className="flex items-center gap-3">
                      {link.icon && <span>{link.icon}</span>}
                      {link.link ? (
                        <a
                          href={link.link}
                          className="text-white-500"
                        >
                          {link.title}
                        </a>
                      ) : (
                        <span className="text-white-500">{link.title}</span>
                      )}
                    </div>
                  ))}
                </div>
              </nav>
            </div>
           
          </div>
          
        </div>
        <div className="xl:hidden lg:hidden md:hidden sm:block flex items-center gap-4 mx-24 mt-10">
                  <FaFacebookF size={24} />
                  <FaSquareXTwitter size={24} />
                  <FaInstagram size={24} />
                  <FaLinkedin size={24} />
                  <FaMedium size={24} />
                  <FaYoutube size={24} />
                </div>
      </div>
      <div className="bg-gray-100">
        <div className="mx-auto max-w-screen-2xl px-4 md:px-8">
          <div className="flex items-center justify-between gap-4 py-2">
            <span className="text-sm text-gray-400">
              Copyright 2024 Ranas Enterprises
            </span>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

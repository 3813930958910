import React, { useEffect, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade, Autoplay } from "swiper/modules";
import { CiLocationOn } from "react-icons/ci";
import { MdEnergySavingsLeaf } from "react-icons/md";
// import { FaHourglass, FaSackDollar } from "react-icons/fa6";
// import { Link } from "react-router-dom";

import "swiper/css";
import "swiper/css/effect-fade";

import Card_1_1 from "../../Assets/ProjectPage/Card/service-car-1-1.webp";
import Card_1_2 from "../../Assets/ProjectPage/Card/service-car-1-2.webp";
import Card_1_3 from "../../Assets/ProjectPage/Card/service-car-1-3.webp";
import Card_1_4 from "../../Assets/ProjectPage/Card/service-car-1-4.webp";

import Card_2_1 from "../../Assets/ProjectPage/Card/service-car-2-1.webp";
import Card_2_2 from "../../Assets/ProjectPage/Card/service-car-2-2.webp";
import Card_2_3 from "../../Assets/ProjectPage/Card/service-car-2-3.webp";
import Card_2_4 from "../../Assets/ProjectPage/Card/service-car-2-4.webp";

import Card_3_1 from "../../Assets/ProjectPage/Card/service-car-3-1.webp";
import Card_3_2 from "../../Assets/ProjectPage/Card/service-car-3-2.webp";
import Card_3_3 from "../../Assets/ProjectPage/Card/service-car-3-3.webp";
import Card_3_4 from "../../Assets/ProjectPage/Card/service-car-3-4.webp";

import people from "../../Assets/SVG_images/people.webp";
import building from "../../Assets/SVG_images/building.webp";
import geometry from "../../Assets/SVG_images/geometry.webp";
import engineerIcon from "../../Assets/SVG_images/final.webp";
import Line from "../../Assets/SVG_images/Line.webp";

const cardArray = [
  {
    swiperArray: [
      {
        img: Card_1_1,
        alt: "",
      },
      {
        img: Card_1_2,
        alt: "",
      },
      {
        img: Card_1_3,
        alt: "",
      },
      {
        img: Card_1_4,
        alt: "",
      },
    ],
    // title: "Kardian Office Solar Installation",
    // location: "Nagpur, India",
    // duration: "36 months",
    // budget: "Rs. 1.8 Lacs",
    // desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor djwincididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commod Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    title: "Ashish’s Mihan Panel Installation",
    location: "Mihan, Nagpur",
    capacity: "10kW",
    desc: "Ashish, aiming to maximize electricity generation and reduce bills, planned to install solar panels for both his office and home in Mihan, Nagpur. With a capacity of 10kW, he sought a comprehensive solution to meet his energy needs efficiently.",
  },
  {
    swiperArray: [
      {
        img: Card_2_1,
        alt: "",
      },
      {
        img: Card_2_2,
        alt: "",
      },
      {
        img: Card_2_3,
        alt: "",
      },
      {
        img: Card_2_4,
        alt: "",
      },
    ],
    // title: "Bridge Reconstruction - Interstate Highway",
    // location: "Jaipur, India",
    // duration: "12 months",
    // budget: "Rs. 3.1 Lacs",
    // desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor djwincididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commod Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    title: "Subhash’s Hingna Solar Plant Installation",
    location: "Hingna, Nagpur",
    capacity: "3kW",
    desc: "Subhash wanted to install solar panels for his office in Hingna, Nagpur, to save on electricity bills. He contacted Rana's Solar, who helped with the installation of a 3kW system on his office terrace within a budget of 1.8 lakhs over a 36-month duration.",
  },
  {
    swiperArray: [
      {
        img: Card_3_1,
        alt: "",
      },
      {
        img: Card_3_2,
        alt: "",
      },
      {
        img: Card_3_3,
        alt: "",
      },
      {
        img: Card_3_4,
        alt: "",
      },
    ],
    title: "Suresh’s Hudkeshwar Panel Installation",
    location: "Hudkeshwar, Nagpur",
    capacity: "5kW",
    desc: "Suresh, a resident of Hudkeshwar in Nagpur, opted to enhance his home with a sustainable energy solution by installing solar panels. With a capacity of 5kW, he sought out a reliable provider in the area to assist with the installation process.",
  },
];

const progressArray = [
  {
    img: people,
    alt: "person png",
    step: "Step 1",
  },
  {
    img: building,
    alt: "building icon",
    step: "Step 2",
  },
  {
    img: geometry,
    alt: "geometry tools icon",
    step: "Step 3",
  },
  {
    img: engineerIcon,
    alt: "construction icon",
    step: "Final",
  },
];

const Service = () => {
  return (
    <>
      <section className="max-w-[1600px] mx-auto w-11/12 xl:w-10/12 flex flex-col lg:gap-y-20 gap-y-0 lg:mt-20 mt-12">
        {cardArray?.map((ele, i) => (
          <ServiceCard key={ele.title} {...ele} index={i} />
        ))}
      </section>
      <section className="max-w-[1600px] mx-auto w-11/12 xl:w-10/12 md:mt-12 mt-5">
        <div className="hidden md:flex justify-center items-center gap-x-6 md:flex-row  flex-col gap-y-8 md:gap-y-0">
          {progressArray?.map((ele, i) => (
            <>
              <div
                key={i}
                className="flex justify-center items-center flex-col "
              >
                <img src={ele.img} alt={ele.alt} className="md:w-24 w-14" />
                <p className="font-medium md:text-2xl text-xl lg:text-3xl">
                  {ele.step}
                </p>
              </div>
              {i !== progressArray.length - 1 && (
                <div className=" rotate-90 w-16 md:w-28 md:rotate-0">
                  <img src={Line} alt="line" />
                </div>
              )}
            </>
          ))}
        </div>
        <div className="px-5 max-w-[1200px] font-medium text-sm block sm:hidden justify-center items-center">
          <div className="flex flex-row justify-center items-center">
            <div className="flex justify-center items-center flex-col mr-6">
              <img src={people} alt="people png" />
              <p className="whitespace-nowrap">Step 1</p>
            </div>
            <div className="mt-4 h-1 w-full bg-customGreen rounded-lg mr-6"></div>
            <div className="flex justify-center items-center flex-col mr-6">
              <img src={building} alt="building icon" />
              <p className="whitespace-nowrap">Step 2</p>
            </div>
          </div>
          <div className="flex flex-row justify-between px-11">
            <div className="mt-4 h-10 w-1  bg-transparent rounded-lg"></div>
            <div className="mt-4 h-10 w-1  bg-customGreen rounded-lg"></div>
          </div>
          <div className="flex flex-row justify-center items-center">
            <div className="flex justify-center items-center flex-col mr-6">
              <img src={engineerIcon} alt="construction icon" />
              <p className="whitespace-nowrap">Final</p>
            </div>
            <div className="mt-4 h-1 w-full bg-customGreen rounded-lg mr-6"></div>
            <div className="flex justify-center items-center flex-col mr-6">
              <img src={geometry} alt="geometry tools icon" />
              <p className="whitespace-nowrap">Step 3</p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Service;

const ServiceCard = ({
  swiperArray,
  title,
  desc,
  // duration,
  // budget,
  location,
  capacity,
  index,
}) => {
  const ref = useRef();
  const handleMouseEnter = () => {
    ref.current.swiper.autoplay.start();
  };
  const handleMouseLeave = () => {
    ref.current.swiper.autoplay.stop();
  };
  useEffect(() => {
    ref.current.swiper.autoplay.stop();
  }, []);
  return (
    <div
      className={` flex justify-center items-center flex-col ${
        index === 0 || index % 2 === 0 ? "lg:flex-row" : "lg:flex-row-reverse"
      }`}
    >
      <div className="inline-block w-full md:w-10/12 lg:w-6/12">
        <Swiper
          ref={ref}
          effect={"fade"}
          autoplay={{
            delay: 600,
            disableOnInteraction: false,
          }}
          modules={[Autoplay, EffectFade]}
          className="mySwiper"
        >
          {swiperArray?.map((ele, i) => (
            <SwiperSlide key={i}>
              <div
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                className=" p-1.5 rounded-lg"
              >
                <img
                  src={ele.img}
                  alt={ele.alt}
                  className=" ring-4 ring-customGreen rounded-2xl"
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      <div
        className={`border-2 shadow-2xl w-11/12 -top-20 lg:top-0 md:w-8/12 lg:w-5/12 rounded-lg inline-block py-6 px-4 text-sm relative  z-10 bg-white ${
          index === 0 || index % 2 === 0 ? "lg:-left-20" : "lg:-right-20"
        }`}
      >
        <div>
          <p className='font-["Poppins"] text-xl lg:text-2xl font-semibold text-[#25500C]'>
            {title}
          </p>
        </div>
        <div className="flex flex-col justify-start items-start gap-y-2 mt-3">
          <div className="flex justify-start items-center gap-x-2">
            <div className=" bg-customGreen rounded-full p-1">
              <CiLocationOn size={15} color="white" />
            </div>
            <p>Location</p>
            <p className="ms-7">{location}</p>
          </div>
          <div className="flex justify-start items-center gap-x-2">
            <div className=" bg-customGreen rounded-full p-1">
              <MdEnergySavingsLeaf size={15} color="white" />
            </div>
            <p>Capacity</p>
            <p className="ms-7">{capacity}</p>
          </div>
          {/* <div className="flex justify-start items-center gap-x-2">
            <div className=" bg-customGreen rounded-full p-1">
              <FaHourglass size={15} color="white" />
            </div>
            <p>duration</p>
            <p className="ms-7">{duration}</p>
          </div>
          <div className="flex justify-start items-center gap-x-2">
            <div className=" bg-customGreen rounded-full p-1">
              <FaSackDollar size={15} color="white" />
            </div>
            <p>budget</p>
            <p className="ms-7">{budget}</p>
          </div> */}
        </div>
        <div className="mt-2">
          <p className="text-[#4E4D4D] justify-center items-center text-justify line-clamp-4 xl:line-clamp-5">
            {desc}
          </p>
        </div>
        {/* <div className="mt-2">
          <Link
            to={"/"}
            className="bg-black text-white px-2 py-1 rounded-full font-medium"
          >
            Read More
          </Link>
        </div> */}
      </div>
    </div>
  );
};

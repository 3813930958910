import React from "react";

import NewNavbar from "../Components/Navbar/NewNavbar";

import HeroSection from "../Components/ContactUsPage/HeroSection";
import Embed from "../Components/ContactUsPage/Embeds";
import Address from "../Components/ContactUsPage/Address";

const NewContactUsPage = () => {
  return (
    <div className=" relative">
      <NewNavbar />
      <HeroSection />
      <Embed />
      <Address />
    </div>
  );
};

export default NewContactUsPage;

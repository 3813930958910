import React from "react";
import { Link } from "react-router-dom";
import { Autoplay, EffectFade, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import HeroImage1 from "../../../Assets/Products/Heroimage1.webp";
import HeroImage2 from "../../../Assets/Products/Heroimage2.webp";
import HeroImage3 from "../../../Assets/Products/Heroimage3.webp";

const cardArray = [HeroImage1, HeroImage2, HeroImage3];

const Herosection = () => {
  return (
    <div className=" max-w-[1600px]  mx-auto">
      <div className=" ">
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          modules={[EffectFade, Autoplay]}
          effect={"fade"}
          speed={1500}
          autoplay={{ delay: 1000 }}
          navigation={false}
          // pagination={{ clickable: false }}
          // scrollbar={{ draggable: true }}
          // onSlideChange={(swiper)=>handleAdd(swiper)}
          onSwiper={(swiper) => console.log(swiper)}
          className=" !pb-16"
        >
          {cardArray.map((el, i) => (
            <SwiperSlide key={i}>
              <section
                style={{
                  background: `url(${el})`,
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
              >
                <div className="w-11/12 mx-auto max-w-[1600px] md:h-[76vh] h-screen pt-20 pb-28 flex flex-col justify-center gap-y-6">
                  <h1 className=' lg:text-[41px] lg:leading-10 md:text-3xl text-2xl font-semibold text-[#FFFFFF] font-["Poppins"] stroke-red-500 '>
                    Transform your world with solar-powered excellence
                  </h1>
                  <h2 className="md:text-2xl text-lg text-[#FFFFFF] ">
                    The power of sunlight with our solar-powered gadgets –
                    smart, eco-friendly solutions that seamlessly integrate into
                    your daily routine, putting the sun's energy at your
                    fingertips.
                  </h2>
                  <div className="mt-5">
                    <Link
                      to={"/"}
                      className='font-["Poppins"] font-medium bg-white px-6 py-[10px] text-black rounded-full'
                    >
                      Shop now
                    </Link>
                  </div>
                </div>
              </section>
            </SwiperSlide>
          ))}
          {/* <div className=' text-center mt-6'>
                        <Link to={"#"} className='py-3 px-6 rounded-3xl bg-[#79C44F] font-semibold'>View All</Link>
                    </div> */}
        </Swiper>
      </div>
    </div>
  );
};

export default Herosection;

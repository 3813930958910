import React from "react";
// import Rooftop from "../../../Assets/rooftop_work.webp";

const Embed = () => {
  return (
    <section className="">
      <div className="w-full md:flex-row flex-col flex items-stretch max-w-[1660px] mx-auto md:pl-0 xl:p-10">
        <div className="lg:w-1/2 md:w-8/12 flex items-center justify-center">
          <iframe
            title="Google Map"
            className="w-full h-[450px] md:h-[500px] lg:h-[550px] border-none rounded-lg shadow-lg hidden xl:block"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3722.6130328614895!2d78.99613987477082!3d21.08811228573829!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bd4956b43ffffff%3A0x61a8472bcd9e9eee!2sRanas%20Enterprises!5e0!3m2!1sen!2sin!4v1706267646282!5m2!1sen!2sin"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
        <div className="lg:w-1/2 md:w-8/12 xl:p-10">
          <iframe
            title="Form"
            aria-label="Contact Form - Ranas Enterprises"
            frameborder="5"
            className="w-full h-[500px] border-none rounded-lg shadow-lg"
            src="https://forms.zohopublic.in/gbjbuzz/form/ContactFormRanasEnterprises/formperma/UYnbh5U-8LQQXg_xuOaquMatf1kl7wil4WXZ0tDghtQ"
          />
        </div>
      </div>
    </section>
  );
};

export default Embed;

import React from "react";
// import { MdShoppingCart } from "react-icons/md";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "swiper/css/free-mode";
import "swiper/css/scrollbar";

// import required modules
import { FreeMode, Scrollbar } from "swiper/modules";

// import proImg1 from "../../../Assets/Products/SolarPowerbank1000mah.webp";
import proImg2 from "../../../Assets/Products/solar-power-system.webp";
import proImg3 from "../../../Assets/Products/SolarStreetLight.webp";
import proImg4 from "../../../Assets/Products/SolarModule.webp";
// import proImg5 from "../../../Assets/Products/SolarMC4connector.webp";
import proImg6 from "../../../Assets/Products/UTLInverterSystem.webp";
// import proImg7 from "../../../Assets/Products/UTLHomeUPS.webp";
// import proImg8 from "../../../Assets/Products/LightSystem.webp";
// import proImg9 from "../../../Assets/Products/SolarWaterHeater.webp";
import proImg10 from "../../../Assets/Products/agriculture-solar-water-pumping-system.webp";
import proImg11 from "../../../Assets/Products/SolarInverter.webp";


import Battery from "../../../Assets/LandingPage/Products/Battery-2.webp";
import Ups from "../../../Assets/LandingPage/Products/ups-1.webp";
import Solar from "../../../Assets/LandingPage/Products/solar-1.webp";
import { Link } from "react-router-dom";

const cardArray = [
  {
    title: "Solar Power System",
    price: "₹ 60,000/KW (Before Subsidy)",
    img: proImg2,
    brand: "UTL",
    status: "Pre-order",
  },
  {
    title: "Solar Street Light",
    price: "₹ 4,500-80,000/unit",
    img: proImg3,
    brand: "UTL",
    status: "Pre-order",
  },
  {
    title: "Solar Module",
    price: "₹ 6,000/unit",
    img: proImg4,
    brand: "UTL",
    status: "Pre-order",
  },
  {
    title: "UTL Inverter System",
    price: "₹ 10,000-12,000/unit",
    img: proImg6,
    brand: "UTL",
    status: "Pre-order",
  },
];

const buttonArray = [
  {
    title: "Solar Panels",
  },
  {
    title: "Solar Street Lights",
  },
  {
    title: "Solar Inverter",
  },
  {
    title: "Solar Water Pump",
  },
  {
    title: "Solar Batteries",
  },
];

const Products = () => {
  return (
    <section className="bg-[#eaf3f5] pt-10 mt-7 pb-6 lg:pb-10">
      <div className="max-w-[1600px] mx-auto w-11/12 xl:w-10/12 mt-10">
        <div className="lg:w-11/12 xl:w-10/12 mx-auto">
          <h2 className="font-bold md:font-normal xl:text-5xl md:text-3xl text-2xl text-center md:text-left">
            Experience Better Solution with Our Products
          </h2>
          <p className="text-lg mt-4">
            Explore our comprehensive collection of top-tier solar materials and
            equipment. Meticulously chosen to empower the success of your
            projects, our high-quality offerings ensure efficiency and
            sustainability.
          </p>
          <div className="flex flex-wrap gap-y-3 justify-start gap-x-3 items-center mt-5">
            {buttonArray?.map((ele, i) => (
              <button
                type="button"
                className='text-xs sm:text-sm md:text-base rounded-3xl font-normal bg-[#070606] text-white md:px-5 md:py-3 py-2 px-3 font-["Poppins"] '
                key={i}
              >
                {ele.title}
              </button>
            ))}
          </div>
        </div>
        <div className="hidden md:grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mt-14">
          {cardArray?.map((ele) => (
            <ProductCard key={ele.price} {...ele} />
          ))}
        </div>
        <div className="md:hidden mt-5">
          <Swiper
            slidesPerView={1}
            spaceBetween={30}
            // freeMode={true}
            // pagination={{
            //   clickable: true,
            // }}
            // modules={[FreeMode]}
            // modules={[Pagination]}
            freeMode={true}
            scrollbar={true}
            modules={[FreeMode, Scrollbar]}
            className="mySwiper"
          >
            {cardArray?.map((ele) => (
              <SwiperSlide key={ele.price}>
                <ProductCard {...ele} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
      <div className="flex justify-center items-center mt-10">
        <Link
          to={"/products"}
          className='text-xs sm:text-sm md:text-base rounded-3xl font-normal bg-[#070606] text-white md:px-5 md:py-3 py-2 px-3 font-["Poppins"] '
        >
          View All Products
        </Link>
      </div>
    </section>
  );
};

export default Products;

const ProductCard = ({ title, img, price, brand, status }) => (
  <div
    className={`max-w-[400px] mx-auto md:mx-0 p-5 rounded-xl flex flex-col gap-y-2 relative bg-white py-5 shadow-sm shadow-black-900 md:shadow-xl`}
    key={title}
  >
    <div className="flex justify-center items-center h-[220px]">
      <img src={img} alt="" className="object-fit " />
    </div>
    <div className=" px-2">
      <div>
        <p className="font-medium text-xl">{title}</p>
        <p className="text-[#333333]">{price}</p>
      </div>
      <div className="font-semibold flex justify-between items-center mt-5">
        <p>Brand</p>
        <p>{brand}</p>
      </div>
      <div className="font-semibold flex justify-between items-center">
        <p>Status:</p>
        <p className="text-[#60A838]">{status}</p>
      </div>
      <div className="mt-4 flex justify-center gap-x-3 items-center">
      <Link to={"/contact-us"}  className="bg-[#79C44F] font-medium rounded-3xl w-3/6 md:w-full py-2 px-3 text-center">
      <button
          type="button"
        >
          Buy Now
        </button>

      </Link>
   
        {/* <MdShoppingCart className="bg-white rounded-full border-2 py-1 px-3 w-14 h-12 border-black" /> */}
      </div>
    </div>
  </div>
);

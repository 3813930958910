import { Drawer } from '@material-tailwind/react'
import React from 'react'

const filterByPrice = ["Under 2000", "2000-5000", "5000-8000", "8000 & above"]
const filterByProduct = ["Solar Panel", "Invertors", "Off grid", "Lights", "Batteries", "Hybrid System", "Agri Products", "Home UPS", "Solar Modules", "Solar Pumps", "Voltage type"]

const RightDrawer = ({filterId,openRight,closeDrawerRight}) => {
    return (
        <header>
            <Drawer
                placement="right"
                open={openRight}
                onClose={closeDrawerRight}
                className="p-4"
                size={240}
                style={{ display: "flex" }}
            >
                {
                    filterId === 1
                        ? (
                            <div className=' flex flex-col items-end'>
                                {
                                    filterByPrice?.map((el, i) => (
                                        <div className='w-44 flex gap-x-4 items-center mt-5 mb-1 border-b border-[#79C44F] border-solid' key={i} >
                                            <input type="checkbox" value={el} placeholder='Under 2000' className='w-4 h-4' />
                                            <label htmlFor="" className=' text-lg mb-1'>{el}</label>
                                        </div>
                                    ))
                                }
                            </div>
                        )
                        : (
                            <div className='h-[96vh] flex flex-col items-end  justify-between'>
                                {
                                    filterByProduct?.map((el, i) => (
                                        <div className='w-44 flex items-center gap-x-4 mb-1 border-b border-[#79C44F] border-solid' key={i}>
                                            <input type="checkbox" value={el} placeholder='Under 2000' className='w-4 h-4' />
                                            <label htmlFor="" className=' text-lg mb-1'>{el}</label>
                                        </div>
                                    ))
                                }
                            </div>
                        )
                }
            </Drawer>
        </header>
    )
}

export default RightDrawer

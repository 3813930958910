import React from "react";
import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
// import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import Solar1 from "../../../Assets/LandingPage/Project/solar-panel-1.jpg";
import Solar2 from "../../../Assets/LandingPage/Project/solar-panel-2.jpg";
import Solar3 from "../../../Assets/LandingPage/Project/solar-panel-3.webp";

import { Link } from "react-router-dom";

const cardArray = [
  {
    // title: "Kardian Office Solar Installation",
    // desc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry...",
    // price: "₹4000 - ₹13000",
    // img: Solar1,
    // location: "UTL",
    // duration: "36 months",
    // budget: "1.8 lakhs",
    title: "Ashish’s Mihan Panel Installation",
    location: "Mihan, Nagpur",
    capacity: "10kW",
    img: Solar1,
    desc: "Ashish, aiming to maximize electricity generation and reduce bills, planned to install solar panels for both his office and home in Mihan, Nagpur. With a capacity of 10kW, he sought a comprehensive solution to meet his energy needs efficiently.",
  },
  {
    // title: "Bridge Recontruction - Interstate Highway",
    // desc: "It was popularised in the 1960s with the release of Letraset sheets...",
    // price: "₹10000 - ₹13000",
    // img: Solar2,
    // location: "UTL",
    // duration: "12 months",
    // budget: "3.1 lakhs",
    title: "Subhash’s Hingna Solar Plant Installation",
    location: "Hingna, Nagpur",
    capacity: "3kW",
    img: Solar2,
    desc: "Subhash wanted to install solar panels for his office in Hingna, Nagpur, to save on electricity bills. He contacted Rana's Solar, who helped with the installation of a 3kW system on his office terrace within a budget of 1.8 lakhs over a 36-month duration.",
  },
  {
    // title: "Residential Housing Development",
    // desc: "As opposed to using 'Content here, content here', making it look like readable English...",
    // price: "₹2000 - ₹8000",
    // img: Solar3,
    // location: "Structural Pro",
    // duration: "22 months",
    // budget: "2.1 lakhs",
    title: "Suresh’s Hudkeshwar Panel Installation",
    location: "Hudkeshwar, Nagpur",
    capacity: "5kW",
    img: Solar3,
    desc: "Suresh, a resident of Hudkeshwar in Nagpur, opted to enhance his home with a sustainable energy solution by installing solar panels. With a capacity of 5kW, he sought out a reliable provider in the area to assist with the installation process.",
  },
  {
    title: "Residential Housing Development",
    // price: "₹2000 - ₹8000",
    location: "Structural Pro",
    capacity: "5kW",
    img: Solar3,
    desc: "As opposed to using 'Content here, content here', making it look like readable English...",
    // duration: "22 months",
    // budget: "2.1 lakhs",
  },
];

const Project = () => {
  // const handleAdd = (swiper) => {
  //   console.log("slide change", swiper.activeIndex + 1);
  // };
  return (
    <section>
      <div className="max-w-[1600px] mx-auto w-11/12 xl:w-10/12 mt-16 my-15 md:my-20">
        <div className="lg:w-11/12 xl:w-10/12 mx-auto">
          <h2 className="font-bold md:font-normal xl:text-5xl md:text-3xl text-2xl text-center lg:text-left">
            Our Remarkable Project Portfolio
          </h2>
          <p className="text-sm leading-4 md:text-lg mt-4 lg:text-left text-center">
            Discover our diverse portfolio highlighting the depth of our solar
            expertise, ranging from impactful installations to eco-friendly
            solutions.
          </p>
        </div>
        <div className="my-14" id="project_corousel">
          <Swiper
            spaceBetween={50}
            breakpoints={{
              300: {
                // width: 576,
                slidesPerView: 1,
              },
              700: {
                // width: 768,
                slidesPerView: 2,
              },
              1024: {
                // width: 1024,
                slidesPerView: 3,
              },
            }}
            modules={ [Pagination]}
            // pagination={{ clickable: true }}
            // scrollbar={{ draggable: true }}
            // onSlideChange={(swiper)=>handleAdd(swiper)}
            onSwiper={(swiper) => console.log(swiper)}
            className=" !pb-16"
          >
            {cardArray.map((el, i) => (
              <SwiperSlide>
                <div className="flex flex-col md:gap-y-4 gap-y-3 my-6">
                  <img
                    src={el.img}
                    alt="solar-panel"
                    className="w-full rounded-2xl"
                  />
                  <h5 className=" md:text-3xl text-2xl font-semibold">
                    {el.title}
                  </h5>
                  <p className=" td:text-lg font-medium line-clamp-2">
                    {el.desc}
                  </p>
                  <div className=" flex flex-col gap-y-1">
                    <div className=" flex justify-between">
                      <p>Location</p>
                      <p>{el.location}</p>
                    </div>
                    <div className=" flex justify-between">
                      <p>Capacity</p>
                      <p>{el.capacity}</p>
                    </div>
                    {/* <div className=" flex justify-between">
                      <p>Total Budget</p>
                      <p>₹ {el.budget}</p>
                    </div> */}
                  </div>
                  <Link className="font-semibold text-center py-3 rounded-3xl border-solid border-2 border-black hover:border-transparent  hover:bg-[#79C44F] transition-all duration-200 ease-linear">
                    Read more
                  </Link>
                </div>
              </SwiperSlide>
            ))}
            <div className=" text-center mt-6">
              <Link
                to={"#"}
                className="py-3 px-6 rounded-3xl bg-[#79C44F] font-semibold"
              >
                View All
              </Link>
            </div>
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default Project;
